<template>
<section class="section">
  <div class="text-center container-fluid col-md-auto">
    <br>
    <h1>Items</h1>
    <table class="table table-hover">
      <caption>Items</caption>
      <thead>
        <th scope="col">Name</th>
        <th scope="col">Description</th>
        <th scope="col">Buy</th>
        <th scope="col">Sell</th>
        <th scope="col">Rarity (Higher is better)</th>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.name">
          <td>{{item.name}}</td>
          <td>{{item.description}}</td>
          <td>{{item.buy}}</td>
          <td>{{item.sell}}</td>
          <td>{{item.rarity}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
</template>

<script>
import axios from "axios";

export default {
  name: "items",
  data() {
    return {
      items: [],
    };
  },

  created() {
    this.getItems();
  },

  methods: {
    async getItems() {
      try {
        const res = await axios.get("https://snailracing.co/api/items");
        this.items = res.data;
      } catch (err) {
        console.log(err)
      }
    }
  }
};
</script>

<style lang="css" scoped>
    .section{
      padding: 5% 7%;
    }
</style>
